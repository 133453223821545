import React from 'react';
import { emojis } from "../utils/data"
import { lengthOf, random } from "werkstatt"
import Content from "../components/Content"
import Layout from "../components/Layout"

const FourOFour = () => {
  const getRandomEmoji = () => {
    return emojis[random(1, lengthOf(emojis) - 1, 0)]
  }

  return (
    <Layout>
      <Content>
        <div className="m-t-25">
          <h1 className="flex flex-center f-z-25">Cicerone | Blog</h1>
          <div className="flex flex-center f-z-25">Recurso no encontrado. ¿Escibiste bien la URL?</div>
          <div className="error-emoji">{getRandomEmoji()}</div>
        </div>
      </Content>
    </Layout>
  )
};

export default FourOFour;
