export const getFlag = (name) => {
  switch (name) {
    case 'spanish':
      return '🇪🇸';
    case 'german':
      return '🇩🇪';
    case 'italian':
      return '🇮🇹';
    case 'latin':
      return '🇻🇦';
    case 'english':
      return '🇬🇧';
    default:
      return '';
  }
};

const resourcesData = [
  {
    id: 1,
    type: 'Podcasts / Radio',
    languages: [
      {
        flag: '🇪🇸',
        resources: [
          {
            name: 'Learning Spanish for Beginners Podcast',
            url: 'https://podcasts.apple.com/gt/podcast/learning-spanish-for-beginners-podcast/id685821493?l=en',
          },
        ],
      },
      {
        flag: '🇩🇪',
        resources: [
          {
            name: 'Slow German',
            url: 'https://podcasts.apple.com/gt/podcast/slow-german/id1085828103?l=en',
          },
          {
            name: 'Learn German by Podcast',
            url: 'https://podcasts.apple.com/gt/podcast/learn-german-by-podcast/id439843599?l=en',
          },
          {
            name: 'Learn German | GermanPod101.com',
            url: 'https://podcasts.apple.com/gt/podcast/learn-german-germanpod101-com/id275948755?l=en',
          },
          {
            name: 'Langsam gesprochene Nachrichten',
            url: 'https://podcasts.apple.com/gt/podcast/langsam-gesprochene-nachrichten-deutsch-lernen-deutsche/id282930329?l=en',
          },
          {
            name: 'El audio-trainer | Aprender alemán',
            url: 'https://podcasts.apple.com/gt/podcast/el-audio-trainer-aprender-alem%C3%A1n-deutsche-welle/id1026750312?l=en',
          },
          {
            name: 'Deutsch - warum nicht?',
            url: 'https://podcasts.apple.com/gt/podcast/deutsch-warum-nicht-series-2-learning-german-deutsche/id268481668?l=en',
          },
          {
            name: 'Top-Thema mit Vokabeln',
            url: 'https://podcasts.apple.com/gt/podcast/top-thema-mit-vokabeln-deutsch-lernen-deutsche-welle/id282932005?l=en',
          },
          {
            name: 'Easy German',
            url: 'https://www.easygerman.org/podcast',
          },
          {
            name: 'Radio en Alemán',
            url: 'https://www.deutschland.fm/',
          },
        ],
      },
      {
        flag: '🇩🇪🇪🇸🇮🇹',
        resources: [
          {
            name: 'Coffee Break Languages',
            url: 'https://coffeebreaklanguages.com/',
          },
        ],
      },
      {
        flag: '🇻🇦',
        resources: [
          {
            name: 'Polis',
            url: 'https://www.polisjerusalem.org/resource/forum-lectiones-latinitatis-vivae/',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    type: 'Gramática / Traductores',
    languages: [
      {
        flag: '🇬🇧🇮🇹🇩🇪🇪🇸',
        resources: [
          {
            name: 'Reverso',
            url: 'https://conjugator.reverso.net/conjugation-english.html',
          },
          {
            name: 'Leo',
            url: 'https://www.leo.org/',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    type: 'Videos / Series',
    languages: [
      {
        flag: '🇩🇪',
        resources: [
          {
            name: 'Easy German',
            url: 'https://www.youtube.com/channel/UCbxb2fqe9oNgglAoYqsYOtQ',
          },
          {
            name: 'AndyGM en Berlín',
            url: 'https://www.youtube.com/channel/UCxM5vSXEfHnhPiFHRTyINIg',
          },
          {
            name: 'Learn German with Anja',
            url: 'https://www.youtube.com/c/LearnGermanwithAnja',
          },
          {
            name: 'Deutsch mit Benjamin',
            url: 'https://www.youtube.com/channel/UC1xaY8XtSMaJN38RYJoGGCg',
          },
          {
            name: 'Richtig Deutsch sprechen',
            url: 'https://www.youtube.com/channel/UCA3gSLdR0rWjvj7UcFWaGlQ',
          },
          {
            name: 'German with Laura',
            url: 'https://www.youtube.com/channel/UCkMHiPVq_Zl55BeBofIhXog',
          },
          {
            name: 'Deutsch mit Armin',
            url: 'https://www.youtube.com/channel/UCdvYQUqZxN0srTlD54-42qA',
          },
          {
            name: 'fröhlich Deutsch',
            url: 'https://www.youtube.com/channel/UCQNR5ZJ8fzyWPvsZGyAVORA',
          },
          {
            name: 'BookBox German',
            url: 'https://www.youtube.com/channel/UCHEzhKyjlp60u0Kgqc_ikNA',
          },
          {
            name: 'EXTRA on Youtube',
            url: 'https://www.youtube.com/watch?v=F6khA8eZaD4&list=PLM45RE_YsqS5-S58HSmYOhu2m-tRul9jW',
          },
          {
            name: 'Planet Schule',
            url: 'https://www.planet-schule.de/sf/wissenspool.php',
          },
        ],
      },
      {
        flag: '🇬🇧',
        resources: [
          {
            name: 'The English Coach',
            url: 'https://www.youtube.com/channel/UC-g0gSStENkYPXFRsKrlvyA',
          },
          {
            name: 'Amigos Ingleses',
            url: 'https://www.youtube.com/c/AmigosIngleses/featured',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    type: 'Cursos',
    languages: [
      {
        flag: '🇩🇪',
        resources: [
          {
            name: 'SeedLang',
            url: 'https://seedlang.com/',
          },
          {
            name: 'Lingvico',
            url: 'https://lingvico.net/',
          },
        ],
      },
      {
        flag: '🇩🇪🇬🇧',
        resources: [
          {
            name: 'Curso de Inglés',
            url: 'https://www.curso-ingles.com/',
          },
          {
            name: 'Perfect English Grammar',
            url: 'https://www.perfect-english-grammar.com/',
          },
          {
            name: 'British Council',
            url: 'https://learnenglish.britishcouncil.org/',
          },
          {
            name: 'Duolingo',
            url: 'https://www.duolingo.com/',
          },
          {
            name: 'Busuu',
            url: 'https://www.busuu.com/',
          },
        ],
      },
      {
        flag: '🇬🇧🇮🇹🇩🇪🇪🇸',
        resources: [
          {
            name: 'Lingua',
            url: 'https://lingua.com/',
          },

        ],
      },
      {
        flag: '🇻🇦',
        resources: [
          {
            name: 'AcademiaLatin',
            url: 'https://academialatin.com/',
          },
        ],
      },
    ],
  },
  {
    id: 5,
    type: 'Chat / Llamadas',
    languages: [
      {
        flag: '🇩🇪🇪🇸🇮🇹',
        resources: [
          {
            name: 'HelloTalk',
            url: 'https://www.hellotalk.com/',
          },
          {
            name: 'Tandem',
            url: 'https://www.tandem.net/',
          },
          {
            name: 'iTalki',
            url: 'https://www.italki.com/',
          },
          {
            name: 'Clubhouse',
            url: 'https://www.joinclubhouse.com/',
          },
        ],
      },
    ],
  },
  {
    id: 6,
    type: 'Libros',
    languages: [
      {
        flag: '🇻🇦',
        resources: [
          {
            name: 'Forum',
            url: 'https://www.amazon.com/Forum-Lectiones-Latinitatis-Speaking-Language/dp/9657698073',
          },
        ],
      },
    ],
  },
  {
    id: 7,
    type: 'Diccionarios',
    languages: [
      {
        flag: '🇪🇸',
        resources: [
          {
            name: 'Real Academia Española',
            url: 'https://dle.rae.es/',
          },
        ],
      },
      {
        flag: '🇬🇧',
        resources: [
          {
            name: 'Merriam Webster',
            url: 'https://www.merriam-webster.com/dictionary',
          },
          {
            name: 'Cambridge Dictionary',
            url: 'https://dictionary.cambridge.org/',
          },
          {
            name: 'Yourdictionary',
            url: 'https://www.yourdictionary.com/',
          },
          {
            name: 'Oxford English Dictionary',
            url: 'https://www.oed.com/',
          },
        ],
      },
      {
        flag: '🇩🇪',
        resources: [
          {
            name: 'Gesellschaft für deutsche Sprache',
            url: 'https://gfds.de/',
          },
        ],
      },
    ],
  },
  {
    id: 8,
    type: 'Cuentos / Artículos',
    languages: [
      {
        flag: '🇬🇧🇮🇹🇩🇪🇪🇸',
        resources: [
          {
            name: 'The Fable Cottage',
            url: 'https://www.thefablecottage.com/',
          },
        ],
      },
    ],
  },
  {
    id: 9,
    type: 'Películas',
    languages: [
      {
        flag: '🇩🇪',
        resources: [
          {
            name: 'Good Bye Lenin!',
            url: 'https://www.imdb.com/title/tt0301357/',
          },
        ],
      },
    ],
  },
  {
    id: 10,
    type: 'Blogs',
    languages: [
      {
        flag: '🇪🇸',
        resources: [
          {
            name: 'Fundéu',
            url: 'https://www.fundeu.es/',
          },
        ],
      },
      {
        flag: '🇩🇪',
        resources: [
          {
            name: 'Your Daily German',
            url: 'https://yourdailygerman.com/',
          },
        ],
      },
    ],
  },
  {
    id: 11,
    type: 'Instagram / Pinterest',
    languages: [
      {
        flag: '🇩🇪',
        resources: [
          {
            name: 'Dein Sprachcoach',
            url: 'https://www.instagram.com/dein_sprachcoach/',
          },
          {
            name: 'German',
            url: 'https://pin.it/1rtbt76',
          },
        ],
      },
    ],
  },
  {
    id: 12,
    type: 'Noticias',
    languages: [
      {
        flag: '🇩🇪',
        resources: [
          {
            name: 'Tagesschau',
            url: 'https://www.tagesschau.de/',
          },
          {
            name: 'Der Spiegel',
            url: 'https://www.spiegel.de/',
          },
        ],
      },
    ],
  },
];

const improvementAreasData = [
  {
    name: 'Learning Spanish for Beginners Podcast',
    url: 'https://podcasts.apple.com/gt/podcast/learning-spanish-for-beginners-podcast/id685821493?l=en',
    skills: ['listening'],
    languages: ['spanish'],
  },
  {
    name: 'Slow German',
    url: 'https://podcasts.apple.com/gt/podcast/slow-german/id1085828103?l=en',
    skills: ['listening'],
    languages: ['german'],
  },
  {
    name: 'Learn German by Podcast',
    url: 'https://podcasts.apple.com/gt/podcast/learn-german-by-podcast/id439843599?l=en',
    skills: ['listening'],
    languages: ['german'],
  },
  {
    name: 'Learn German | GermanPod101.com',
    url: 'https://podcasts.apple.com/gt/podcast/learn-german-germanpod101-com/id275948755?l=en',
    skills: ['listening'],
    languages: ['german'],
  },
  {
    name: 'Langsam gesprochene Nachrichten',
    url: 'https://podcasts.apple.com/gt/podcast/langsam-gesprochene-nachrichten-deutsch-lernen-deutsche/id282930329?l=en',
    skills: ['listening'],
    languages: ['german'],
  },
  {
    name: 'El audio-trainer | Aprender alemán',
    url: 'https://podcasts.apple.com/gt/podcast/el-audio-trainer-aprender-alem%C3%A1n-deutsche-welle/id1026750312?l=en',
    skills: ['listening'],
    languages: ['german'],
  },
  {
    name: 'Deutsch - warum nicht?',
    url: 'https://podcasts.apple.com/gt/podcast/deutsch-warum-nicht-series-2-learning-german-deutsche/id268481668?l=en',
    skills: ['listening'],
    languages: ['german'],
  },
  {
    name: 'Top-Thema mit Vokabeln',
    url: 'https://podcasts.apple.com/gt/podcast/top-thema-mit-vokabeln-deutsch-lernen-deutsche-welle/id282932005?l=en',
    skills: ['listening'],
    languages: ['german'],
  },
  {
    name: 'Easy German',
    url: 'https://www.easygerman.org/podcast',
    skills: ['listening'],
    languages: ['german'],
  },
  {
    name: 'Radio en Alemán',
    url: 'https://www.deutschland.fm/',
    skills: ['listening'],
    languages: ['german'],
  },
  {
    name: 'Coffee Break Languages',
    url: 'https://coffeebreaklanguages.com/',
    skills: ['listening'],
    languages: ['german', 'spanish', 'italian'],
  },
  {
    name: 'Polis',
    url: 'https://www.polisjerusalem.org/resource/forum-lectiones-latinitatis-vivae/',
    skills: ['listening'],
    languages: ['latin'],
  },
  {
    name: 'Reverso',
    url: 'https://conjugator.reverso.net/conjugation-english.html',
    skills: ['vocabulary'],
    languages: ['german', 'spanish', 'italian', 'english'],
  },
  {
    name: 'Leo',
    url: 'https://www.leo.org/',
    skills: ['vocabulary'],
    languages: ['german', 'spanish', 'italian', 'english'],
  },
  {
    name: 'Easy German',
    url: 'https://www.youtube.com/channel/UCbxb2fqe9oNgglAoYqsYOtQ',
    skills: ['vocabulary', 'listening'],
    languages: ['german'],
  },
  {
    name: 'AndyGM en Berlín',
    url: 'https://www.youtube.com/channel/UCxM5vSXEfHnhPiFHRTyINIg',
    skills: ['vocabulary', 'grammar'],
    languages: ['german'],
  },
  {
    name: 'Learn German with Anja',
    url: 'https://www.youtube.com/c/LearnGermanwithAnja',
    skills: ['vocabulary', 'grammar'],
    languages: ['german'],
  },
  {
    name: 'Deutsch mit Benjamin',
    url: 'https://www.youtube.com/channel/UC1xaY8XtSMaJN38RYJoGGCg',
    skills: ['vocabulary', 'grammar'],
    languages: ['german'],
  },
  {
    name: 'Richtig Deutsch sprechen',
    url: 'https://www.youtube.com/channel/UCA3gSLdR0rWjvj7UcFWaGlQ',
    skills: ['vocabulary', 'grammar'],
    languages: ['german'],
  },
  {
    name: 'German with Laura',
    url: 'https://www.youtube.com/channel/UCkMHiPVq_Zl55BeBofIhXog',
    skills: ['vocabulary', 'grammar'],
    languages: ['german'],
  },
  {
    name: 'Deutsch mit Armin',
    url: 'https://www.youtube.com/channel/UCdvYQUqZxN0srTlD54-42qA',
    skills: ['vocabulary', 'grammar'],
    languages: ['german'],
  },
  {
    name: 'fröhlich Deutsch',
    url: 'https://www.youtube.com/channel/UCQNR5ZJ8fzyWPvsZGyAVORA',
    skills: ['vocabulary', 'grammar'],
    languages: ['german'],
  },
  {
    name: 'BookBox German',
    url: 'https://www.youtube.com/channel/UCHEzhKyjlp60u0Kgqc_ikNA',
    skills: ['vocabulary', 'grammar', 'listening'],
    languages: ['german'],
  },
  {
    name: 'The English Coach',
    url: 'https://www.youtube.com/channel/UC-g0gSStENkYPXFRsKrlvyA',
    skills: ['vocabulary', 'grammar', 'listening'],
    languages: ['english'],
  },
  {
    name: 'Amigos Ingleses',
    url: 'https://www.youtube.com/c/AmigosIngleses/featured',
    skills: ['vocabulary', 'grammar', 'listening'],
    languages: ['english'],
  },
  {
    name: 'Planet Schule',
    url: 'https://www.planet-schule.de/sf/wissenspool.php',
    skills: ['vocabulary', 'grammar', 'listening', 'reading'],
    languages: ['german', 'english'],
  },
  {
    name: 'SeedLang',
    url: 'https://seedlang.com/',
    skills: ['vocabulary', 'grammar', 'listening', 'reading', 'writing'],
    languages: ['german'],
  },
  {
    name: 'Lingvico',
    url: 'https://lingvico.net/',
    skills: ['vocabulary', 'grammar', 'listening', 'reading', 'writing'],
    languages: ['german'],
  },
  {
    name: 'Curso de Inglés',
    url: 'https://www.curso-ingles.com/',
    skills: ['vocabulary', 'grammar'],
    languages: ['german', 'english'],
  },
  {
    name: 'Perfect English Grammar',
    url: 'https://www.perfect-english-grammar.com/',
    skills: ['vocabulary', 'grammar'],
    languages: ['german', 'english'],
  },
  {
    name: 'British Council',
    url: 'https://learnenglish.britishcouncil.org/',
    skills: ['vocabulary', 'grammar'],
    languages: ['german', 'english'],
  },
  {
    name: 'Duolingo',
    url: 'https://www.duolingo.com/',
    skills: ['vocabulary', 'listening', 'reading', 'writing'],
    languages: ['german', 'english'],
  },
  {
    name: 'Busuu',
    url: 'https://www.busuu.com/',
    skills: ['vocabulary', 'grammar', 'listening', 'reading', 'writing'],
    languages: ['german', 'english'],
  },
  {
    name: 'Lingua',
    url: 'https://lingua.com/',
    skills: ['vocabulary', 'listening', 'reading'],
    languages: ['german', 'spanish', 'italian', 'english'],
  },
  {
    name: 'AcademiaLatin',
    url: 'https://academialatin.com/',
    skills: ['vocabulary', 'grammar', 'listening', 'reading', 'writing'],
    languages: ['latin'],
  },
  {
    name: 'HelloTalk',
    url: 'https://www.hellotalk.com/',
    skills: ['vocabulary', 'listening', 'reading', 'writing', 'conversation'],
    languages: ['german', 'spanish', 'italian'],
  },
  {
    name: 'Tandem',
    url: 'https://www.tandem.net/',
    skills: ['vocabulary', 'listening', 'reading', 'writing', 'conversation'],
    languages: ['german', 'spanish', 'italian'],
  },
  {
    name: 'iTalki',
    url: 'https://www.italki.com/',
    skills: ['vocabulary', 'grammar', 'listening', 'conversation'],
    languages: ['german', 'spanish', 'italian'],
  },
  {
    name: 'Clubhouse',
    url: 'https://www.joinclubhouse.com/',
    skills: ['listening', 'conversation'],
    languages: ['german', 'spanish', 'italian'],
  },
  {
    name: 'Forum',
    url: 'https://www.amazon.com/Forum-Lectiones-Latinitatis-Speaking-Language/dp/9657698073',
    skills: ['vocabulary', 'grammar', 'listening', 'reading', 'writing'],
    languages: ['latin'],
  },
  {
    name: 'Real Academia Española',
    url: 'https://dle.rae.es/',
    skills: ['vocabulary', 'grammar'],
    languages: ['spanish'],
  },
  {
    name: 'Merriam Webster',
    url: 'https://www.merriam-webster.com/dictionary',
    skills: ['vocabulary', 'grammar'],
    languages: ['english'],
  },
  {
    name: 'Cambridge Dictionary',
    url: 'https://dictionary.cambridge.org/',
    skills: ['vocabulary', 'grammar'],
    languages: ['english'],
  },
  {
    name: 'Yourdictionary',
    url: 'https://www.yourdictionary.com/',
    skills: ['vocabulary', 'grammar'],
    languages: ['english'],
  },
  {
    name: 'Oxford English Dictionary',
    url: 'https://www.oed.com/',
    skills: ['vocabulary', 'grammar'],
    languages: ['english'],
  },
  {
    name: 'Gesellschaft für deutsche Sprache',
    url: 'https://gfds.de/',
    skills: ['vocabulary', 'grammar'],
    languages: ['german'],
  },
  {
    name: 'The Fable Cottage',
    url: 'https://www.thefablecottage.com/',
    skills: ['vocabulary', 'reading'],
    languages: ['german', 'spanish', 'italian', 'english'],
  },
  {
    name: 'Good Bye Lenin!',
    url: 'https://www.imdb.com/title/tt0301357/',
    skills: ['vocabulary', 'listening'],
    languages: ['german'],
  },
  {
    name: 'Fundéu',
    url: 'https://www.fundeu.es/',
    skills: ['grammar'],
    languages: ['spanish'],
  },
  {
    name: 'Your Daily German',
    url: 'https://yourdailygerman.com/',
    skills: ['vocabulary', 'grammar', 'listening', 'reading'],
    languages: ['german'],
  },
  {
    name: 'Dein Sprachcoach',
    url: 'https://www.instagram.com/dein_sprachcoach/',
    skills: ['vocabulary', 'grammar', 'listening'],
    languages: ['german'],
  },
  {
    name: 'German',
    url: 'https://pin.it/1rtbt76',
    skills: ['vocabulary', 'grammar'],
    languages: ['german'],
  },
  {
    name: 'Tagesschau',
    url: 'https://www.tagesschau.de/',
    skills: ['listening', 'reading'],
    languages: ['german'],
  },
  {
    name: 'Der Spiegel',
    url: 'https://www.spiegel.de/',
    skills: ['listening', 'reading'],
    languages: ['german'],
  },
  {
    name: 'Wooflang',
    url: 'https://wooflang.com/',
    skills: ['vocabulary', 'grammar', 'listening', 'reading', 'writing'],
    languages: ['german', 'italian', 'english', 'spanish'],
  },
  {
    name: 'EXTRA on Youtube',
    url: 'https://www.youtube.com/watch?v=F6khA8eZaD4&list=PLM45RE_YsqS5-S58HSmYOhu2m-tRul9jW',
    skills: ['vocabulary', 'listening'],
    languages: ['german'],
  },
];

const resourcesOptions = [
  { id: 1, name: 'Podcasts / Radio' },
  { id: 2, name: 'Gramática / Traductores' },
  { id: 3, name: 'Videos / Series' },
  { id: 4, name: 'Cursos' },
  { id: 5, name: 'Chat / Llamadas' },
  { id: 6, name: 'Libros' },
  { id: 7, name: 'Diccionarios' },
  { id: 8, name: 'Cuentos / Artículos' },
  { id: 9, name: 'Películas' },
  { id: 10, name: 'Blogs' },
  { id: 11, name: 'Instagram / Pinterest' },
  { id: 12, name: 'Noticias' },
];

const improvementAreasOptions = [
  { id: 'reading', name: 'Lectura' },
  { id: 'writing', name: 'Escritura' },
  { id: 'vocabulary', name: 'Vocabulario' },
  { id: 'listening', name: 'Audición' },
  { id: 'conversation', name: 'Conversación' },
  { id: 'grammar', name: 'Gramática' },
];

const emojis = [
  '(^-^*)',
  '(≥o≤)',
  '(>_<)',
  '(·_·)',
  '\\(o_o)/',
  '(ㆆ_ㆆ)',
  '( ˘︹˘ )',
  '¯\\_(ツ)_/¯',
  'ಠ_ಠ',
  '(ಥ﹏ಥ)',
  'ಠ╭╮ಠ',
  '◉_◉',
  '⚆ _ ⚆',
  '(；一_一)',
  '(¬_¬)',
  'ಠ~ಠ',
  '◔ ⌣ ◔',
];

export {
  resourcesData, emojis, resourcesOptions, improvementAreasOptions, improvementAreasData,
};
